import React, { FC } from 'react'
import { LogoSVG } from 'components/Logo'

type AuthFormContainerType = {}

const AuthFormContainer: FC<AuthFormContainerType> = ({ children }) => {
  return (
    <main className='justify-content-center align-items-center d-flex min-vh-100 py-4 w-100'>
      <div
        style={{ minWidth: 450 }}
        className='rounded shadow-2 col-4 d-flex flex-column gap-4 bg-white px-4 px-lg-6 py-8'
      >
        <div className='text-center'>
          <LogoSVG height={100} width={100} />
        </div>
        {children}
      </div>
    </main>
  )
}

export default AuthFormContainer
